import { Auth, API, Storage } from "aws-amplify";
import { generate } from "@wcj/generate-password";
import { v4 as uuidv4 } from "uuid";

import * as authTypes from "./types";
import * as routeTypes from "../../lib/constants";
import { getUser } from "../../graphql/queries/user";
import {
  updateUser,
  deleteProfile,
  updateTraderUser,
} from "../../graphql/mutations/user";
import {
  signUpDB,
  getUserByCognitoId,
  signUpSocialDB,
} from "../../services/auth.service";
import { userExistCheckApi } from "../../services/verification.service";
import * as toastFunction from "../../toastify/index";
import { emailRegex } from "../../lib/manageFields";
import log from "loglevel";
import {
  cleanPhoneNumber,
  isValidPhoneNumber,
} from "../../common/phoneNumberValidation";
import { logoutKommunicateSession } from "../../components/Kommunicate/KommuincateHelperFunctions";
import { auth, db } from "../../lib/firebase";
import {
  signInWithCustomToken,
  signOut as firebaseAuthSignOut,
  onAuthStateChanged,
} from "firebase/auth";
import { collection, getDocs } from "firebase/firestore";

const setAuthData = (name, value) => (dispatch) => {
  dispatch({
    type: authTypes.SET_AUTH_DATA,
    payload: { [name]: value },
  });
};

const signIn = async ({ emailResult, username }) => {
  let method = emailResult ? "EMAIL" : "PHONE";

  if (method === "PHONE") {
    username = routeTypes.COUNTRY_CODE + cleanPhoneNumber(username);
  }

  let user = await Auth.signIn(username);
  // Resettng Viewed Cars undo count
  localStorage.setItem("undoCount", 0);
  localStorage.removeItem("FindYourCarDetails");
  return await Auth.sendCustomChallengeAnswer(
    user,
    "LOGIN_WITH_EMAIL_OR_PHONE",
    { LOGIN_WITH_EMAIL_OR_PHONE: method },
  );
};

const signInWithToken = async ({ email, socialMediaID }) => {
  let user = await Auth.signIn(email);
  user = await Auth.sendCustomChallengeAnswer(
    user,
    "LOGIN_WITH_EMAIL_OR_PHONE",
    { LOGIN_WITH_EMAIL_OR_PHONE: "TOKEN" },
  );
  localStorage.removeItem("FindYourCarDetails");
  return Auth.sendCustomChallengeAnswer(user, socialMediaID);
};

const verifyChallenge = async (cognitoUser, verifyCode) => {
  await Auth.sendCustomChallengeAnswer(cognitoUser, verifyCode);
  const response = await Auth.currentAuthenticatedUser();
  return response;
};

function handleSignUpSuccess({
  email,
  newRandomPassword,
  values,
  imageFile,
  imageName,
  setShowThanksModal,
  setLoading,
  s3Repo,
  userType,
  dispatch,
}) {
  Auth.configure({
    authenticationFlowType: "USER_SRP_AUTH",
  });
  Auth.signIn(email, newRandomPassword)
    .then(async (res2) => {
      const data = await Auth.currentAuthenticatedUser();
      localStorage.setItem("loggedInUser", "");
      localStorage.setItem("loggedInUser", JSON.stringify(data));
      localStorage.setItem(
        "wsac_web",
        JSON.stringify({
          expiry: new Date().getTime() + 10 * 1000,
          token: data.signInUserSession.accessToken.jwtToken,
        }),
      );

      let loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
      let cognitoId = loggedInUser.attributes.sub;
      let S3_REPO =
        process.env.REACT_APP_USER_POOL_REGION +
        ":" +
        loggedInUser.attributes.sub +
        "/" +
        s3Repo;
      localStorage.removeItem("FindYourCarDetails");
      if (userType === routeTypes.PRIVATE) {
        signUpDB({ ...values, cognitoId })
          .then(() => {
            setLoading(false);
            setShowThanksModal(true);
          })
          .catch((err) => {
            setLoading(false);
            log.error(err);
            toastFunction.showErrorToast("Something went wrong");
          });
      } else {
        uploadLogo(S3_REPO, routeTypes.S3_ACCESS_LEVEL, imageFile, imageName)
          .then((result) => {
            const companyLogo = result
              ? `${process.env.REACT_APP_AWS_S3_BUCKET_URL}/${routeTypes.S3_ACCESS_LEVEL}/${result.key}`
              : null;
            const newCompanyContact = values.companyContact;
            const inputs = {
              ...values,
              avatarImage: companyLogo,
              cognitoId,
              addressLine1: values.addressLine1,
              town: values.town,
              trader: {
                addressLine1: values.addressLine1,
                companyContact: newCompanyContact
                  ? routeTypes.COUNTRY_CODE +
                    cleanPhoneNumber(newCompanyContact)
                  : "",
                companyDescription: values.companyDescription,
                companyName: values.companyName,
                companyWebsiteUrl: values.companyWebsiteUrl,
                email: values.email,
                phone: values.phone,
                town: values.town,
                logo: companyLogo,
              },
            };
            if (inputs.trader.companyWebsiteUrl === "") {
              delete inputs.trader["companyWebsiteUrl"];
            }
            signUpDB(inputs)
              .then(() => {
                setLoading(false);
                setShowThanksModal(true);
              })
              .catch((err) => {
                setLoading(false);
                log.error(err);

                handleSignUpError(err);
              });
          })
          .catch((err) => {
            dispatch({
              type: authTypes.LOADING_TOGGLE_ACTION,
              payload: false,
            });
            log.error(err);
            toastFunction.showErrorToast("Something went wrong");
          });
      }
    })
    .catch((err) => {
      dispatch({
        type: authTypes.LOADING_TOGGLE_ACTION,
        payload: false,
      });
      log.error(err);
      toastFunction.showErrorToast("Something went wrong");
    });
}

function handleSignUpError(err) {
  log.error(err);

  err?.errors.forEach((error) => {
    if (error.message === "Phone number already exists!") {
      toastFunction.showErrorToast(routeTypes.USER_EXISTS);
    } else {
      toastFunction.showErrorToast("Something went wrong");
    }
  });
}

const signUp = async ({
  values,
  userType,
  s3Repo,
  imageFile,
  imageName,
  setLoading,
  dispatch,
  setShowThanksModal,
}) => {
  const { email, firstName, lastName, phone } = values;
  const mobileNumber =
    cleanPhoneNumber(phone) === ""
      ? ""
      : routeTypes.COUNTRY_CODE + cleanPhoneNumber(phone);
  const username = uuidv4();
  const newRandomPassword = generateRandomPassword(36);
  const attributes = mobileNumber
    ? {
        email,
        given_name: firstName,
        family_name: lastName,
        name: firstName + " " + lastName,
        phone_number: mobileNumber,
        profile: "user",
      }
    : {
        email,
        given_name: firstName,
        family_name: lastName,
        name: firstName + " " + lastName,
        profile: "user",
      };
  const params = {
    username,
    password: newRandomPassword,
    attributes: attributes,
  };
  Auth.signUp(params)
    .then((res) => {
      handleSignUpSuccess({
        email,
        newRandomPassword,
        values,
        imageFile,
        imageName,
        setShowThanksModal,
        setLoading,
        s3Repo,
        userType,
        dispatch,
      });
    })
    .catch((err) => {
      log(err);
      toastFunction.showErrorToast("Something went wrong");
    });
};

const signUpSocial = async ({
  values,
  userType,
  s3Repo,
  imageFile,
  imageName,
  setLoading,
  dispatch,
  setShowThanksModal,
}) => {
  let loggedInUser = JSON.parse(localStorage.getItem("socialUser"));
  let cognitoId = loggedInUser.sub;
  const { email, firstName, lastName, phone } = values;
  let bodyData = {
    email: email,
    firstName: firstName,
    lastName: lastName,
    phoneNumber: routeTypes.COUNTRY_CODE + cleanPhoneNumber(phone),
    profile: "user",
    cognitoId,
  };
  const apiName = "AuthenticatedApi";
  const path = "/auth/update-cognito";
  const myInit = {
    body: bodyData,
  };
  const response = await API.post(apiName, path, myInit);
  log.debug("resp", response);
  if (response && response.code === "200") {
    const cognitoProfile = await Auth.currentAuthenticatedUser();
    const { username: socialMediaID } = cognitoProfile;
    let [socialMediaType, socialId] = socialMediaID.split("_");
    if (!socialId) {
      socialMediaType = "twitter";
    }
    values = {
      ...values,
      socialMediaType,
      socialMediaID,
    };
    let user;
    let S3_REPO;

    try {
      const user_result = await getUserByCognitoId({
        key: "email",
        value: email,
      });
      user = user_result?.data?.getUserDetails;
      S3_REPO = user?._id + "/" + s3Repo;
    } catch (error) {
      S3_REPO = "profileImage" + "/" + s3Repo;
    }

    // Remove socialUser once we are done with the flow
    localStorage.removeItem("socialUser");
    if (userType === routeTypes.PRIVATE) {
      signUpDB({ ...values, cognitoId })
        .then(() => {
          setShowThanksModal(true);
          setLoading(false);
        })
        .catch((err) => {
          log.error("err", err);
          toastFunction.showErrorToast("Something went wrong");
          setLoading(false);
        });
    } else {
      uploadLogo(S3_REPO, routeTypes.S3_ACCESS_LEVEL, imageFile, imageName)
        .then((result) => {
          const companyLogo1 = result?.key
            ? `${process.env.REACT_APP_AWS_S3_BUCKET_URL}/${routeTypes.S3_ACCESS_LEVEL}/${result.key}`
            : null;
          const inputs = {
            ...values,
            //avatarImage: "",
            avatarImage: companyLogo1,
            addressLine1: "",
            cognitoId,
            trader: {
              email: values.email,
              addressLine1: values.addressLine1,
              town: values.town,
              companyDescription: values.companyDescription,
              companyName: values.companyName,
              companyWebsiteUrl: values.companyWebsiteUrl,
              phone: routeTypes.COUNTRY_CODE + cleanPhoneNumber(values.phone),
              companyContact: values.companyContact
                ? routeTypes.COUNTRY_CODE +
                  cleanPhoneNumber(values.companyContact)
                : "",
              logo: companyLogo1,
            },
            town: values.town,
            id: user?._id,
          };
          if (inputs.trader.companyWebsiteUrl === "") {
            delete inputs.trader["companyWebsiteUrl"];
          }
          log.debug("inputs", inputs);
          signUpSocialDB(inputs)
            .then((res) => {
              log.debug("res", res);
              setLoading(false);
              setShowThanksModal(true);
            })
            .catch((err) => {
              log.error("err", err);
              setLoading(false);
              handleSignUpError(err);
            });
        })
        .catch((err) => {
          log.error("err", err);
          setLoading(false);
          dispatch({
            type: authTypes.LOADING_TOGGLE_ACTION,
            payload: false,
          });
          toastFunction.showErrorToast("Something went wrong");
        });
    }
  } else {
    dispatch({
      type: authTypes.LOADING_TOGGLE_ACTION,
      payload: false,
    });
    toastFunction.showErrorToast("Something went wrong");
  }
};

const updateUserProfile = async (
  values,
  s3Repo,
  imageFile,
  imageName,
  dispatch,
  user = {},
) => {
  try {
    const {
      id,
      firstName,
      lastName,
      phoneVerified,
      companyName,
      companyLogo,
      companyWebsiteUrl,
      companyDescription,
      town,
      postCode,
      addressLine1,
      companyContact,
      userType,
      traderId,
      userLocation,
    } = values;
    dispatch({ type: authTypes.LOADING_TOGGLE_ACTION, payload: false });
    if (imageFile) {
      let loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
      if (loggedInUser == null || Object.keys(loggedInUser).length === 0) {
        loggedInUser = user;
      }
      let S3_REPO =
        process.env.REACT_APP_USER_POOL_REGION +
        ":" +
        (loggedInUser.attributes
          ? loggedInUser.attributes.sub
          : loggedInUser._id) +
        "/" +
        s3Repo;
      let response = uploadLogo(
        S3_REPO,
        routeTypes.S3_ACCESS_LEVEL,
        imageFile,
        imageName,
      )
        .then(async (result) => {
          const companyLogo = `${process.env.REACT_APP_AWS_S3_BUCKET_URL}/${routeTypes.S3_ACCESS_LEVEL}/${result.key}`;
          const response = await API.graphql({
            query: updateTraderUser,
            variables: {
              id,
              firstName,
              lastName,
              phoneVerified,
              town,
              postCode,
              addressLine1,
              userType,
              traderId,
              userLocation,
              trader: {
                addressLine1,
                companyName,
                logo: companyLogo,
                companyWebsiteUrl,
                companyDescription,
                companyContact:
                  companyContact !== ""
                    ? routeTypes.COUNTRY_CODE + cleanPhoneNumber(companyContact)
                    : companyContact,
                town,
              },
            },
          });
          log.debug("Update response", response);
          return response.data;
        })
        .catch((err) => {
          log.error("err", err);
          dispatch({
            type: authTypes.LOADING_TOGGLE_ACTION,
            payload: false,
          });
          toastFunction.showErrorToast("Something went wrong");
        });
      return response;
    } else {
      const response = await API.graphql({
        query: updateTraderUser,
        variables: {
          id,
          firstName,
          lastName,
          phoneVerified,
          town,
          postCode,
          addressLine1,
          userType,
          traderId,
          userLocation,
          trader: {
            addressLine1,
            companyName,
            logo: companyLogo,
            companyWebsiteUrl,
            companyContact:
              companyContact !== ""
                ? routeTypes.COUNTRY_CODE + cleanPhoneNumber(companyContact)
                : companyContact,
            companyDescription,
            town,
          },
        },
      });
      log.debug("Update response", response);
      return response.data;
    }
  } catch (error) {
    log.error("error", error);
    dispatch({ type: authTypes.LOADING_TOGGLE_ACTION, payload: false });
  }
};

const uploadLogo = async (S3_REPO, level, imageFile, imageName) => {
  if (!imageFile) {
    return null;
  }
  const trimmedImageName = imageName?.split(" ").join("");
  return await Storage.put(`${S3_REPO}/${trimmedImageName}`, imageFile, {
    level,
    contentType: imageFile.type,
  });
};

const generateRandomPassword = (value) => {
  const randomPassword = generate({
    length: value,
  });
  return randomPassword;
};

const authCheck = (navigate, eventTriggered) => async (dispatch) => {
  try {
    dispatch({ type: authTypes.LOADING_TOGGLE_ACTION, payload: true });
    const data = await Auth.currentAuthenticatedUser();
    localStorage.setItem("loggedInUser", JSON.stringify(data));
    localStorage.setItem(
      "wsac_web",
      JSON.stringify({
        expiry: new Date().getTime() + 10 * 1000,
        token: data.signInUserSession.accessToken.jwtToken,
      }),
    );
    const intitialLogin =
      eventTriggered === "signIn"
        ? "?intitialLogin=false"
        : "?intitialLogin=true";
    let navigateRoute = routeTypes.BASE_ROUTE;
    dispatch({ type: authTypes.LOADING_TOGGLE_ACTION, payload: false });
    navigate(`${navigateRoute}${intitialLogin}`);
  } catch (error) {
    dispatch({ type: authTypes.LOADING_TOGGLE_ACTION, payload: false });
  }
};

const resendConfirmationCode = async (username) => {
  try {
    return await Auth.resendSignUp(username);
  } catch (err) {
    log.error("error resending code: ", err);
  }
};

const signOut = async () => {
  try {
    const rememberMe = localStorage.getItem("rememberMe");
    await Auth.signOut();
    // logout of kommunicate support session
    logoutKommunicateSession();
    // logout of firebase
    await firebaseSignOut();
    localStorage.clear();
    rememberMe && localStorage.setItem("rememberMe", rememberMe);
  } catch (error) {
    log.error("error signing out: ", error);
  }
};

const redirectToLogin = (navigate) => async (dispatch) => {
  try {
    dispatch({ type: authTypes.USER_SIGNOUT });
    navigate(routeTypes.SIGNIN_ROUTE);
  } catch (err) {
    log.error("signout err:", err);
  }
};

const loadingToggleAction = (status) => async (dispatch) => {
  dispatch({ type: authTypes.LOADING_TOGGLE_ACTION, payload: status });
};

const onSubmitVerifyEmail_Ph = ({
  verType,
  data,
  values,
  setVerificationType,
  sendVerifyEmail_Ph,
  setOtpFieldVisibility,
  setShowLoader,
}) => {
  const emailResult = emailRegex.test(values.email);
  const phoneResult = isValidPhoneNumber(values.phone);
  if (verType === "email" && !emailResult) {
    setShowLoader(false);
    toastFunction.showErrorToast("Please enter a valid email address");
    return false;
  } else if (verType === "phone_number" && !phoneResult) {
    setShowLoader(false);
    toastFunction.showErrorToast("Please enter a valid mobile number");
    return false;
  } else {
    const userExistResult = userExistCheckApi(verType, data);
    userExistResult
      .then((res) => {
        setShowLoader(false);
        toastFunction.showErrorToast(routeTypes.USER_EXISTS);
      })
      .catch(() => {
        const result = sendVerifyEmail_Ph(verType, data);
        result
          .then((res) => {
            setShowLoader(false);
            verType === "email"
              ? setVerificationType("email")
              : setVerificationType("phone");
            setOtpFieldVisibility(true);
          })
          .catch((error) => {
            setShowLoader(false);
            toastFunction.showErrorToast("Something went wrong");
          });
      });
  }
};

const onPhoneNumChange = ({
  verType,
  old_data,
  data,
  values,
  updateMyProfilePhone,
  setOtpFieldVisibility,
  setShowLoader,
  setShowConfirmPhoneModal,
  setShowPhoneExistsModal,
}) => {
  const phoneResult = isValidPhoneNumber(values.phone);
  if (verType === "phone_number" && !phoneResult) {
    setShowLoader(false);
    toastFunction.showErrorToast("Please enter a valid mobile number");
    return false;
  } else {
    const loggedEmailId = values?.email;
    updateMyProfilePhone(
      verType,
      old_data,
      cleanPhoneNumber(data),
      true,
      false,
      loggedEmailId,
      "",
    )
      .then((res) => {
        log.debug("res", res);
        setShowLoader(false);
        if (res.message === "OTP_SEND") {
          setOtpFieldVisibility(true);
        } else {
          toastFunction.showSuccessToast("Something new happened");
        }
      })
      .catch((error) => {
        if (error?.response?.data?.message) {
          log.debug("API error message:", error.response.data.message);
          if (error.response.data.message === "CHANGE_REQUEST_EXISTS") {
            log.debug("Change of phone number already exists");
            setShowPhoneExistsModal(true);
          } else if (error.response.data.message === "REQUEST_EXISTS") {
            setShowConfirmPhoneModal(
              true,
              error.response.data.data.newPhoneNumber,
            );
          } else if (
            error.response.data.message === "REQUEST_WITH_NEW_NUMBER_EXIST"
          ) {
            setShowConfirmPhoneModal(true, "");
          } else if (error.response.data.message === "PHONE_NUMBER_EXIST") {
            toastFunction.showErrorToast("This mobile number already exists");
          } else {
            log.debug("error", error);
          }
          setShowLoader(false);
        } else {
          toastFunction.showErrorToast("Something went wrong");
          setShowLoader(false);
        }
      });
  }
};

const onPhoneNumChangeAfterConfirm = ({
  verType,
  old_data,
  data,
  values,
  setVerificationType,
  updateMyProfilePhone,
  updateUserPhone_otp,
  setOtpFieldVisibility,
  setShowLoader,
  setShowConfirmPhoneModal,
  updateMyProfilePhoneWithoutVerify,
  phoneVerification,
}) => {
  const loggedEmailId = values?.email;
  updateMyProfilePhoneWithoutVerify(
    old_data,
    cleanPhoneNumber(data),
    true,
    true,
    loggedEmailId,
    "",
    phoneVerification,
  )
    .then((res) => {
      log.debug("res", res);
      setShowLoader(false);
      if (res.message === "NUMBER_CHANGE_REQUEST_CREATED") {
        toastFunction.showInfoToast(
          "WE SWAP support team approval is required for update",
        );
      }
    })
    .catch((error) => {
      setShowLoader(false);
      toastFunction.showErrorToast("Something went wrong");
    });
};

const amplifyEventListener = (data, navigate) => async (dispatch) => {
  try {
    switch (data.payload.event) {
      // case "signIn":
      //   dispatch({ type: authTypes.LOADING_TOGGLE_ACTION, payload: true });
      //   dispatch(authCheck(navigate, data.payload.event));
      //   break;
      case "tokenRefresh_failure":
        dispatch(redirectToLogin(navigate));
        break;
      case "signOut":
        dispatch(redirectToLogin(navigate));
        break;
      default:
        return;
    }
  } catch (error) {
    log.error("Something went wrong with amplify Listener", error);
  }
};

/**
 * Sample fn to fetch user details from API
 * This fn is added to demonstrate graphql integration
 * To be removed in future updates
 * @param id
 * @returns {Promise<Object>}
 */
const getUserDetails = async (id) => {
  try {
    const response = await API.graphql({ query: getUser, variables: { id } });
    return response.data;
  } catch (e) {
    log.error(e);
  }
};

/**
 * Sample fn to update user details via API
 * This fn is added to demonstrate graphql integration
 * To be removed in future updates
 * @param id
 * @param firstName
 * @returns {Promise<Object>}
 */
const updateUserDetails = async ({ id, firstName }) => {
  try {
    const response = await API.graphql({
      query: updateUser,
      variables: { id, firstName },
    });
    return response.data;
  } catch (e) {
    log.error(e);
  }
};

/**
 * Sample fn to update user details via API
 * This fn is added to demonstrate graphql integration
 * To be removed in future updates
 * @param userId
 * @returns {Promise<Object>}
 */
const deleteUserProfile = async ({ userId }) => {
  try {
    const response = await API.graphql({
      query: deleteProfile,
      variables: { userId },
    });
    return response.data;
  } catch (e) {
    log.error(e);
  }
};

/**
 * Fn authenticates user with firebase auth
 * return auth instance of firebase
 * @returns {Promise<Object>}
 */
const signInWithFireBase = async () => {
  try {
    let firebaseUser = await checkFirebaseAuthStatus();
    if (firebaseUser) {
      // User is signed in
      return firebaseUser;
    }
    // User not signed in
    // Retrieve token for sign-in
    const apiName = "AuthenticatedApi";
    const path = "/syncDb/firebase/authentication";
    const response = await API.get(apiName, path);
    const token = response.data.responseParameter;
    // login with firebase
    firebaseUser = await firebaseSignIn(token);
    return firebaseUser;
  } catch (e) {
    log.error("firebase error", e);
  }
};

/**
 * Fn checks whether user is already authenticated with firebase
 * Return auth instance of firebase if true else returns null
 * @returns {Promise<unknown>}
 */
const checkFirebaseAuthStatus = () => {
  return new Promise((resolve) => {
    try {
      onAuthStateChanged(auth, (user) => {
        resolve(user);
      });
    } catch {
      resolve(null);
    }
  });
};

/**
 * Fn performs firebase authentication with custom token
 * @param token
 * @returns {Promise<User>}
 */
const firebaseSignIn = async (token) => {
  try {
    const userCredential = await signInWithCustomToken(auth, token);
    const user = userCredential.user;
    log.debug({ userCredential: user });
    return user;
  } catch (e) {
    log.error(e);
    throw e;
  }
};

/**
 * Fn Terminates firebase auth session if exists
 * @returns {Promise<void>}
 */
const firebaseSignOut = async () => {
  try {
    log.debug("firebaseSignOut");
    await firebaseAuthSignOut(auth);
  } catch (e) {
    log.error(e);
  }
};

const sampleDbRead = async () => {
  try {
    const querySnapshot = await getDocs(collection(db, "chat_info"));
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      log.debug(doc.id, " => ", doc.data());
    });
  } catch (e) {
    log.error(e);
  }
};

const onPhoneNumChangeWithoutVerify = ({
  old_data,
  data,
  values,
  updateMyProfilePhoneWithoutVerify,
  setShowLoader,
  setShowConfirmPhoneModal,
  setShowPhoneExistsModal,
  phoneVerification,
}) => {
  const loggedEmailId = values?.email;
  updateMyProfilePhoneWithoutVerify(
    old_data,
    cleanPhoneNumber(data),
    true,
    false,
    loggedEmailId,
    "",
    phoneVerification,
  )
    .then((res) => {
      log.debug("res", res);
      setShowLoader(false);
      if (res.message === "NUMBER_CHANGE_REQUEST_CREATED") {
        toastFunction.showInfoToast(
          "WE SWAP support team approval is required for update",
        );
      }
    })
    .catch((error) => {
      if (error?.response?.data?.message) {
        log.debug("API error message:", error.response.data.message);
        if (error.response.data.message === "CHANGE_REQUEST_EXISTS") {
          log.debug("Change of phone number already exists");
          setShowPhoneExistsModal(true);
        } else if (
          error.response.data.message === "REQUEST_WITH_NEW_NUMBER_EXIST"
        ) {
          setShowConfirmPhoneModal(true, "");
        } else if (error.response.data.message === "REQUEST_EXISTS") {
          setShowConfirmPhoneModal(
            true,
            error.response.data.data.newPhoneNumber,
          );
        } else if (error.response.data.message === "PHONE_NUMBER_EXIST") {
          toastFunction.showErrorToast("This mobile number already exists");
        } else {
          log.debug("error", error);
        }
        setShowLoader(false);
      } else {
        toastFunction.showErrorToast("Something went wrong");
        setShowLoader(false);
      }
    });
};

export {
  amplifyEventListener,
  signIn,
  verifyChallenge,
  signUp,
  signUpSocial,
  uploadLogo,
  setAuthData,
  generateRandomPassword,
  authCheck,
  resendConfirmationCode,
  signOut,
  redirectToLogin,
  loadingToggleAction,
  getUserDetails,
  updateUserDetails,
  onSubmitVerifyEmail_Ph,
  updateUserProfile,
  deleteUserProfile,
  onPhoneNumChange,
  signInWithToken,
  onPhoneNumChangeAfterConfirm,
  signInWithFireBase,
  onPhoneNumChangeWithoutVerify,
};
